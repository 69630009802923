import React from "react";
import "../../src/global.css";
import budgetFriendlyImg from "../Assets/img/category/budget friendly.png";
import tripExpertImg from "../Assets/img/category/trip expert.png";
import chargesImg from "../Assets/img/category/charges.png";
import customerSupportImg from "../Assets/img/category/customer support.png";
import ServiceCard from "./Cards/ServiceCard";

const Services = () => {
  return (
    <section className="pt-5 pt-md-9" id="service">
      <div className="container">
        <div className="position-absolute z-index--1 end-0 d-none d-lg-block">
          <img
            src="assets/img/category/shape.svg"
            style={{ maxWidth: "200px" }}
            alt="service"
          />
        </div>
        <div className="mb-7 text-center">
          <h3 className="fs-xl-10 fs-lg-8 fs-7 fw-bold font-cursive text-capitalize">
            We Offer Best Services
          </h3>
        </div>
        <div className="row">
          <ServiceCard
            imgSrc={budgetFriendlyImg}
            title="Budget-Friendly Tour"
            description="Built Wicket longer admire do barton vanity itself do in it."
          />
          <ServiceCard
            imgSrc={tripExpertImg}
            title="Expert Trip Planning"
            description="Engrossed listening. Park gate sell they west hard for the."
          />
          <ServiceCard
            imgSrc={chargesImg}
            title="NO HIDDEN CHARGES"
            description="Barton vanity itself do in it. Preferred to men it engrossed listening."
          />
          <ServiceCard
            imgSrc={customerSupportImg}
            title="24/7* Customer Support"
            description="We deliver outsourced aviation services for military customers."
          />
        </div>
      </div>
    </section>
  );
};

export default Services;
