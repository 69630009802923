import React, { useState, useEffect } from "react";
import "../../src/global.css";
import logoImg from "../Assets/img/logo/logo.png";
import ContactModal from "./Modal/ContactModal";

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const footerStyle = {
    backgroundImage: "none",
    backgroundColor: "rgba(249, 250, 253, 0.1)",
  };

  return (
    <div>
      <ContactModal showModal={showModal} handleClose={handleCloseModal} />

      <nav
        style={footerStyle}
        className={`navbar navbar-expand-lg navbar-light fixed-top d-block backdrop navbar-light ${
          scrolled ? "scrolled" : ""
        }`}
        data-navbar-on-scroll="data-navbar-on-scroll"
      >
        <div className="container">
          <a className="navbar-brand" href="index.php">
            <img src={logoImg} height="100" alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse mt-4 mt-lg-0"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto pt-2 pt-lg-0 font-base align-items-lg-center align-items-start">
              <li className="nav-item px-3 px-xl-4">
                <a
                  className="nav-link fw-medium"
                  aria-current="page"
                  href="#about"
                >
                  About us
                </a>
              </li>
              <li className="nav-item px-3 px-xl-4">
                <a
                  className="nav-link fw-medium"
                  aria-current="page"
                  href="#service"
                >
                  Service
                </a>
              </li>
              <li className="nav-item px-3 px-xl-4">
                <a
                  className="nav-link fw-medium"
                  aria-current="page"
                  href="#destination"
                >
                  Destination
                </a>
              </li>
              <li className="nav-item px-3 px-xl-4">
                <button
                  className="btn btn-outline-dark order-1 order-lg-0 fw-medium"
                  onClick={handleOpenModal}
                >
                  Contact us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
