import React, { useState } from "react";
import "./ContactModal.css";

const ContactModal = ({ showModal, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phno: "",
    message: "",
  });
  const [alertMessage, setAlertMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Form Data:", formData);

    try {
      const response = await fetch("/Script.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setAlertMessage({
          type: "success",
          text: "Form submitted successfully!",
        });
        setTimeout(() => {
          setAlertMessage(null);
          handleClose(); // Close the modal after hiding the alert
        }, 2000); // Hide after 2 seconds
      } else {
        setAlertMessage({
          type: "error",
          text: "There was an issue submitting the form. Please try again.",
        });
        setTimeout(() => setAlertMessage(null), 2000); // Hide after 2 seconds
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setAlertMessage({
        type: "error",
        text: "There was an error. Please try again later.",
      });
      setTimeout(() => setAlertMessage(null), 2000); // Hide after 2 seconds
    }
  };

  return (
    <>
      {alertMessage && (
        <div className={`alert-box ${alertMessage.type}`}>
          {alertMessage.text}
        </div>
      )}

      {showModal && (
        <div className="contact-modal-overlay">
          <div className="contact-modal-content">
            <button className="contact-close-btn" onClick={handleClose}>
              &times;
            </button>
            <h3>Contact Us</h3>
            <form onSubmit={handleSubmit} className="contact-form-container">
              <div className="contact-form-group">
                <label className="contact-form-label" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="contact-form-control"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact-form-group">
                <label className="contact-form-label" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="contact-form-control"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact-form-group">
                <label className="contact-form-label" htmlFor="phno">
                  Mobile Number
                </label>
                <input
                  type="tel"
                  id="phno"
                  name="phno"
                  className="contact-form-control"
                  placeholder="Your Mobile Number"
                  value={formData.phno}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact-form-group">
                <label className="contact-form-label" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="contact-form-control contact-form-textarea"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button className="contact-btn-danger" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactModal;
