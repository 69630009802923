import React from "react";

const Copyright = () => {
  return (
    <footer className="py-4 text-center" style={{ backgroundColor: "#f8f9fa" }}>
      <div className="container">
        <p className="mb-0 text-muted fs-6" style={{ fontSize: "0.875rem" }}>
          © {new Date().getFullYear()}{" "}
          <span className="fw-bold">wowtripss.com</span>. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Copyright;
