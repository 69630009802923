import React from "react";
import "../../src/global.css";

const Contact = () => {
  return (
    <section id="booking">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="mb-4 text-start">
              <h3 className="fs-xl-10 fs-lg-8 fs-7 fw-bold font-cursive text-capitalize">
                Contact us
              </h3>
            </div>
            <div className="d-flex align-items-start mb-5">
              <div className="flex-1">
                <h5 className="text-secondary fw-bold fs-0">Office Address</h5>
                <p>
                  UG - 17 Jaina Tower - 1 District Center Janak Puri{" "}
                  <br className="d-none d-sm-block" />
                  New Delhi - 110058
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-5">
              <div className="flex-1">
                <h5 className="text-secondary fw-bold fs-0">Mail Us</h5>
                <p>
                  info@wowtripss.com <br className="d-none d-sm-block" />
                  b2b@wowtripss.com
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-5">
              <div className="flex-1">
                <h5 className="text-secondary fw-bold fs-0">Phone No.</h5>
                <p>+91 7042151535</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              style={{
                maxWidth: "100%",
                listStyle: "none",
                transition: "none",
                overflow: "hidden",
                width: "500px",
                height: "500px",
              }}
            >
              <div
                id="gmap-canvas"
                style={{ height: "100%", width: "100%", maxWidth: "100%" }}
              >
                <iframe
                  style={{ height: "100%", width: "100%", border: 0 }}
                  src="https://www.google.com/maps/embed/v1/place?q=Jaina+Tower+1,+Shivaji+Marg,+Janakpuri+District+Center,+Janakpuri,+Delhi,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  title="Google Map"
                ></iframe>
              </div>
              <a
                className="our-googlemap-code"
                href="https://www.bootstrapskins.com/themes"
                id="auth-map-data"
              >
                premium bootstrap themes
              </a>
              <style>
                {`
                  #gmap-canvas img {
                    max-width: none !important;
                    background: none !important;
                    font-size: inherit;
                    font-weight: inherit;
                  }
                `}
              </style>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
