import React from "react";
import { Carousel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "../../src/global.css";
import heroBg from "../Assets/img/hero/hero-bg.svg";
import dubaiImg from "../Assets/img/dest/dubai.jpg";
import baliImg from "../Assets/img/dest/bali.jpg";
import singaporeImg from "../Assets/img/dest/singapore.jpg";
import vietnamImg from "../Assets/img/dest/Vietnam.jpg";

const CarouselComp = () => {
  return (
    <section style={{ paddingTop: "7rem" }}>
      <div
        className="bg-holder"
        style={{ backgroundImage: `url(${heroBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      ></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5 col-lg-6 order-0 order-md-1 text-end">
            <div className="pe-7 ps-5 ps-lg-0">
              <Carousel fade>
                <Carousel.Item className="position-relative">
                  <div className="card shadow" style={{ borderRadius: "10px" }}>
                    <div className="card-body p-4">
                      <p className="fw-medium mb-4">
                        <img
                          className="fit-cover"
                          src={dubaiImg}
                          height="400"
                          width="100%"
                          alt="Dubai"
                        />
                      </p>
                      <h5 className="text-secondary">
                        Dubai, Destination Management Company
                      </h5>
                    </div>
                  </div>
                  <div
                    className="card shadow-sm position-absolute top-0 z-index--1 mb-3 w-100 h-100"
                    style={{
                      borderRadius: "10px",
                      transform: "translate(25px, 25px)",
                    }}
                  ></div>
                </Carousel.Item>
                <Carousel.Item className="position-relative">
                  <div className="card shadow" style={{ borderRadius: "10px" }}>
                    <div className="card-body p-4">
                      <p className="fw-medium mb-4">
                        <img
                          className="fit-cover"
                          src={baliImg}
                          height="400"
                          width="100%"
                          alt="Bali"
                        />
                      </p>
                      <h5 className="text-secondary">Bali, Indonesia</h5>
                    </div>
                  </div>
                  <div
                    className="card shadow-sm position-absolute top-0 z-index--1 mb-3 w-100 h-100"
                    style={{
                      borderRadius: "10px",
                      transform: "translate(25px, 25px)",
                    }}
                  ></div>
                </Carousel.Item>
                <Carousel.Item className="position-relative">
                  <div className="card shadow" style={{ borderRadius: "10px" }}>
                    <div className="card-body p-4">
                      <p className="fw-medium mb-4">
                        <img
                          className="fit-cover"
                          src={singaporeImg}
                          height="400"
                          width="100%"
                          alt="Singapore"
                        />
                      </p>
                      <h5 className="text-secondary">Singapore</h5>
                    </div>
                  </div>
                  <div
                    className="card shadow-sm position-absolute top-0 z-index--1 mb-3 w-100 h-100"
                    style={{
                      borderRadius: "10px",
                      transform: "translate(25px, 25px)",
                    }}
                  ></div>
                </Carousel.Item>
                <Carousel.Item className="position-relative">
                  <div className="card shadow" style={{ borderRadius: "10px" }}>
                    <div className="card-body p-4">
                      <p className="fw-medium mb-4">
                        <img
                          className="fit-cover"
                          src={vietnamImg}
                          height="400"
                          width="100%"
                          alt="Vietnam"
                        />
                      </p>
                      <h5 className="text-secondary">Vietnam</h5>
                    </div>
                  </div>
                  <div
                    className="card shadow-sm position-absolute top-0 z-index--1 mb-3 w-100 h-100"
                    style={{
                      borderRadius: "10px",
                      transform: "translate(25px, 25px)",
                    }}
                  ></div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
          <div className="col-md-7 col-lg-6 text-md-start text-center py-6">
            <p className="fw-bold text-danger mb-3">
              Your Canvas Of Experiences
            </p>
            <h3 className="hero-title mb-4">
              Discover the World: Your Journey Begins Here
            </h3>
            <p className="fw-bold custom-danger mb-3 mt-3">
              Dubai, Destination Management Company
            </p>
            <div className="text-center text-md-start mt-4">
              <div className="custom-text">
                <span className="bold-text">Our other B2B destinations:</span>
                <span className="text-content">Singapore | Bali | Vietnam</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarouselComp;
