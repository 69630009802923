import React, { useState } from "react";
import DestinationModal from "./Modal/DestinationModal";
import shapeImg from "../Assets/img/dest/shape.svg";

import dubaiImg from "../Assets/img/dest/dubai.jpg";
import dubaiImg2 from "../Assets/img/dest/Dubai2.jpg";
import dubaiImg3 from "../Assets/img/dest/Dubai3.jpg";


import singaporeImg from "../Assets/img/dest/singpore.jpg";
import singaporeImg2 from "../Assets/img/dest/singapore2.jpg";
import singaporeImg3 from "../Assets/img/dest/singapre3.jpg";

import baliImg from "../Assets/img/dest/bali.jpg";
import baliImg2 from "../Assets/img/dest/Bali2.jpg";
import baliImg3 from "../Assets/img/dest/Bali3.jpg";

import vietnamImg from "../Assets/img/dest/Vietnam.jpg";
import vietnameImg2 from "../Assets/img/dest/vietname2.jpg";
import vietnameImg3 from "../Assets/img/dest/Vietnam3.jpg";

import { DUBAI } from "../Descriptions/Dubai";
import { SINGAPORE } from "../Descriptions/Singapore";
import { BALI } from "../Descriptions/Bali";
import { VIETNAM } from "../Descriptions/Vietnam";

const DestinationSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);

  const destinations = [
    {
      title: "Dubai",
      images: [dubaiImg, dubaiImg2, dubaiImg3],
      description: DUBAI.description,
    },
    {
      title: "Vietnam",
      images: [vietnamImg, vietnameImg2, vietnameImg3],
      description: VIETNAM.description,
    },
    {
      title: "Singapore",
      images: [singaporeImg, singaporeImg2, singaporeImg3],
      description: SINGAPORE.description,
    },
    {
      title: "Bali",
      images: [baliImg, baliImg2, baliImg3],
      description: BALI.description,
    },
  ];

  const handleShowModal = (destination) => {
    setSelectedDestination(destination);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section className="pt-5" id="destination">
      <div className="container">
        <div className="position-absolute start-100 bottom-0 translate-middle-x d-none d-xl-block ms-xl-n4">
          <img src={shapeImg} alt="destination" />
        </div>
        <div className="mb-7 text-center">
          <h5 className="text-secondary">Top Selling</h5>
          <h3 className="fs-xl-10 fs-lg-8 fs-7 fw-bold font-cursive text-capitalize">
            Top Other Destinations
          </h3>
        </div>
        <div className="row justify-content-center">
          {destinations.map((destination, index) => (
            <div className="col-md-3 mb-3" key={index}>
              <div className="card overflow-hidden shadow">
                <img
                  className="card-img-top"
                  src={
                    destination.images[0]
                  } /* Show the first image as the card's thumbnail */
                  alt={destination.title}
                />
                <div className="card-body py-4 px-3">
                  <div className="d-flex flex-column flex-lg-row justify-content-between mb-3">
                    <h4 className="text-secondary fw-medium">
                      <a
                        className="link-900 text-decoration-none stretched-link"
                        href="#!"
                        onClick={() => handleShowModal(destination)}
                      >
                        {destination.title}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {selectedDestination && (
          <DestinationModal
            show={showModal}
            handleClose={handleCloseModal}
            destination={selectedDestination}
          />
        )}
      </div>
    </section>
  );
};

export default DestinationSection;
