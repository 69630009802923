import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./WelcomePopup.css";
import WelcomeImg from "../Assets/img/dest/WelcomeImg.jpg";

const WelcomePopup = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Body className="body-content">
        <div className="image-container">
          <img src={WelcomeImg} alt="logo" className="responsive-image" />
          <Button variant="link" onClick={handleClose} className="close-button">
            &times;
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WelcomePopup;
