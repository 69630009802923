import React from "react";
import { Modal, Carousel } from "react-bootstrap";

const DestinationModal = ({ show, handleClose, destination }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      style={{ zIndex: 1050 }} // Ensure the modal is above the navbar
    >
      <Modal.Header
        closeButton
        style={{
          zIndex: 1051, // Ensure the modal header is above the modal body
          position: "relative",
        }}
      >
        <Modal.Title>{destination.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          zIndex: 1051, // Ensure the modal body is above the modal overlay
          position: "relative",
        }}
      >
        <Carousel>
          {destination.images.map((imgSrc, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={imgSrc}
                alt={`${destination.title} - slide ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="mt-4">
          <h5>About {destination.title}</h5>
          <p>{destination.description}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DestinationModal;
