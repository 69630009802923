import React from "react";
import "./ServiceCard.css";

const ServiceCard = ({ imgSrc, title, description }) => (
  <div className="col-lg-3 col-sm-6 mb-6">
    <div className="card service-card shadow-hover rounded-3 text-center align-items-center">
      <div className="card-body p-xxl-5 p-4">
        <img
          src={imgSrc}
          width="75"
          alt={title}
          style={{ transition: "transform 0.3s ease-in-out" }}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
        />
        <h4 className="mb-3">{title}</h4>
        <p className="mb-0 fw-medium">{description}</p>
      </div>
    </div>
  </div>
);

export default ServiceCard;
